import cls from './DislikeIcon.module.css';

export const DislikeIcon = () => {
	return (
		<div className={cls.container}>
			<svg
				width="22"
				height="20"
				viewBox="0 0 22 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M21.4831 11.7188L20.3581 2.71875C20.2896 2.17489 20.0249 1.67475 19.6137 1.31224C19.2025 0.949741 18.6732 0.749812 18.125 0.75H2C1.60218 0.75 1.22064 0.908035 0.93934 1.18934C0.658035 1.47064 0.5 1.85218 0.5 2.25V10.5C0.5 10.8978 0.658035 11.2794 0.93934 11.5607C1.22064 11.842 1.60218 12 2 12H6.03687L9.57875 19.0856C9.6411 19.2102 9.73693 19.315 9.8555 19.3882C9.97406 19.4614 10.1107 19.5001 10.25 19.5C11.2446 19.5 12.1984 19.1049 12.9017 18.4016C13.6049 17.6984 14 16.7446 14 15.75V14.25H19.25C19.5693 14.2501 19.8849 14.1823 20.176 14.051C20.467 13.9197 20.7268 13.728 20.938 13.4885C21.1492 13.2491 21.3071 12.9675 21.4011 12.6623C21.4951 12.3572 21.523 12.0355 21.4831 11.7188ZM5.75 10.5H2V2.25H5.75V10.5ZM19.8125 12.4959C19.7426 12.5763 19.6561 12.6407 19.5591 12.6845C19.462 12.7284 19.3565 12.7507 19.25 12.75H13.25C13.0511 12.75 12.8603 12.829 12.7197 12.9697C12.579 13.1103 12.5 13.3011 12.5 13.5V15.75C12.5001 16.2702 12.32 16.7744 11.9903 17.1767C11.6606 17.5791 11.2016 17.8548 10.6916 17.9569L7.25 11.0728V2.25H18.125C18.3077 2.24994 18.4842 2.31658 18.6212 2.43742C18.7583 2.55825 18.8465 2.72496 18.8694 2.90625L19.9944 11.9062C20.0084 12.0118 19.9994 12.1192 19.9681 12.221C19.9367 12.3228 19.8836 12.4166 19.8125 12.4959Z"
					fill="#474747"
				/>
			</svg>
		</div>
	);
};
