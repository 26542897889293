import cls from './LoopIcon.module.css';

export const LoopIcon = () => {
	return (
		<div className={cls.container}>
			<svg
				width="15"
				height="16"
				viewBox="0 0 15 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_3431_20694)">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M6.97056 2.5178C4.10686 2.5178 1.78537 4.83928 1.78537 7.70298C1.78537 10.5667 4.10686 12.8882 6.97056 12.8882C9.83426 12.8882 12.1557 10.5667 12.1557 7.70298C12.1557 4.83928 9.83426 2.5178 6.97056 2.5178ZM0.896484 7.70298C0.896484 4.34836 3.61594 1.62891 6.97056 1.62891C10.3252 1.62891 13.0446 4.34836 13.0446 7.70298C13.0446 9.22032 12.4883 10.6077 11.5684 11.6723L13.5071 13.6109C13.6806 13.7845 13.6806 14.0659 13.5071 14.2395C13.3335 14.413 13.0521 14.413 12.8785 14.2395L10.9399 12.3008C9.8753 13.2207 8.4879 13.7771 6.97056 13.7771C3.61594 13.7771 0.896484 11.0576 0.896484 7.70298Z"
						fill="#A3A3A3"
					/>
				</g>
				<defs>
					<clipPath id="clip0_3431_20694">
						<rect
							width="14.2222"
							height="14.2222"
							fill="white"
							transform="translate(0.15625 0.888672)"
						/>
					</clipPath>
				</defs>
			</svg>
		</div>
	);
};
