import cls from './FigmaIcon.module.css';

export const FigmaIcon = () => {
	return (
		<div className={cls.container}>
			<svg
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M10.0234 10.0003C10.0234 8.15937 11.5158 6.66699 13.3568 6.66699C15.1977 6.66699 16.6901 8.15941 16.6901 10.0003C16.6901 11.8413 15.1977 13.3336 13.3568 13.3336C11.5158 13.3336 10.0234 11.8412 10.0234 10.0003Z"
					fill="#00BCFF"
				/>
				<path
					d="M3.35645 16.6667C3.35645 14.8257 4.84883 13.3334 6.68977 13.3334L8.46613 12.3955L10.0231 13.3334V16.6667C10.0231 18.5077 8.5307 20 6.68977 20C4.84883 20 3.35645 18.5076 3.35645 16.6667Z"
					fill="#00CF7F"
				/>
				<path
					d="M10.0228 0L8.20898 3.15117L10.0228 6.66664H13.3094C15.1504 6.66664 16.6427 5.17426 16.6427 3.33332C16.6427 1.49238 15.1503 0 13.3094 0H10.0228Z"
					fill="#FF7361"
				/>
				<path
					d="M3.30957 3.33332C3.30957 5.17426 4.80195 6.66664 6.64289 6.66664L8.41113 7.34961L10.023 6.66664V0H6.64285C4.80195 0 3.30957 1.49238 3.30957 3.33332Z"
					fill="#FF4D12"
				/>
				<path
					d="M3.35645 10.0004C3.35645 11.8413 4.84883 13.3337 6.68977 13.3337H10.0231V6.66699H6.68977C4.84883 6.66699 3.35645 8.15941 3.35645 10.0004Z"
					fill="#B659FF"
				/>
			</svg>
		</div>
	);
};
