import cls from './LikeIcon.module.css';

export const LikeIcon = () => {
	return (
		<div className={cls.container}>
			<svg
				width="22"
				height="20"
				viewBox="0 0 22 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M20.9375 6.51125C20.7263 6.27193 20.4666 6.08028 20.1757 5.94903C19.8847 5.81778 19.5692 5.74994 19.25 5.75H14V4.25C14 3.25544 13.6049 2.30161 12.9017 1.59835C12.1984 0.895088 11.2446 0.5 10.25 0.5C10.1107 0.4999 9.97406 0.538617 9.8555 0.611808C9.73693 0.684999 9.6411 0.789771 9.57875 0.914375L6.03687 8H2C1.60218 8 1.22064 8.15804 0.93934 8.43934C0.658035 8.72064 0.5 9.10218 0.5 9.5V17.75C0.5 18.1478 0.658035 18.5294 0.93934 18.8107C1.22064 19.092 1.60218 19.25 2 19.25H18.125C18.6732 19.2502 19.2025 19.0503 19.6137 18.6878C20.0249 18.3253 20.2896 17.8251 20.3581 17.2812L21.4831 8.28125C21.523 7.9644 21.495 7.64268 21.4009 7.3375C21.3068 7.03232 21.1488 6.75066 20.9375 6.51125ZM2 9.5H5.75V17.75H2V9.5ZM19.9944 8.09375L18.8694 17.0938C18.8465 17.275 18.7583 17.4418 18.6212 17.5626C18.4842 17.6834 18.3077 17.7501 18.125 17.75H7.25V8.92719L10.6916 2.04313C11.2016 2.14521 11.6606 2.4209 11.9903 2.82326C12.32 3.22562 12.5001 3.7298 12.5 4.25V6.5C12.5 6.69891 12.579 6.88968 12.7197 7.03033C12.8603 7.17098 13.0511 7.25 13.25 7.25H19.25C19.3564 7.24996 19.4616 7.27258 19.5587 7.31634C19.6557 7.36011 19.7423 7.42402 19.8127 7.50383C19.8831 7.58363 19.9357 7.67752 19.967 7.77923C19.9984 7.88094 20.0077 7.98816 19.9944 8.09375Z"
					fill="#6A0BFF"
				/>
			</svg>
		</div>
	);
};
