import cls from './UpIcon.module.css';

export const UpIcon = () => {
	return (
		<div className={cls.container}>
			<svg
				width="45"
				height="46"
				viewBox="0 0 45 46"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect width="43" height="45" rx="8" fill="#FFDA85" />
				<path
					d="M23.3957 20.8346C23.3957 21.2834 23.7594 21.6471 24.2082 21.6471H24.955L22.7747 23.8274C22.6689 23.9332 22.4974 23.9332 22.3917 23.8274L20.6737 22.1095C19.9334 21.3691 18.733 21.3691 17.9926 22.1095L15.5086 24.5934C15.1913 24.9107 15.1913 25.4252 15.5086 25.7425C15.8259 26.0598 16.3404 26.0598 16.6577 25.7425L19.1417 23.2585C19.2474 23.1528 19.4189 23.1528 19.5247 23.2585L21.2426 24.9765C21.983 25.7168 23.1834 25.7168 23.9237 24.9765L26.104 22.7962V23.543C26.104 23.9917 26.4678 24.3555 26.9165 24.3555C27.3652 24.3555 27.729 23.9917 27.729 23.543V20.8346C27.729 20.3859 27.3652 20.0221 26.9165 20.0221H24.2082C23.7594 20.0221 23.3957 20.3859 23.3957 20.8346Z"
					fill="#343330"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M21.4377 11.3555C18.9369 11.3555 16.9771 11.3554 15.448 11.561C13.8829 11.7715 12.6479 12.2106 11.6785 13.1799C10.7091 14.1493 10.27 15.3844 10.0596 16.9495C9.85398 18.4786 9.85399 20.4384 9.854 22.9391V23.0635C9.85399 25.5642 9.85398 27.524 10.0596 29.0531C10.27 30.6183 10.7091 31.8533 11.6785 32.8227C12.6479 33.792 13.8829 34.2312 15.448 34.4416C16.9771 34.6472 18.9369 34.6472 21.4377 34.6471H21.562C24.0628 34.6472 26.0225 34.6472 27.5517 34.4416C29.1168 34.2312 30.3518 33.792 31.3212 32.8227C32.2906 31.8533 32.7297 30.6183 32.9401 29.0531C33.1457 27.524 33.1457 25.5642 33.1457 23.0635V22.9391C33.1457 20.4384 33.1457 18.4786 32.9401 16.9495C32.7297 15.3844 32.2906 14.1493 31.3212 13.1799C30.3518 12.2106 29.1168 11.7715 27.5517 11.561C26.0225 11.3554 24.0628 11.3555 21.562 11.3555H21.4377ZM12.8275 14.329C13.4447 13.7119 14.2796 13.3577 15.6645 13.1715C17.0729 12.9822 18.9234 12.9805 21.4998 12.9805C24.0762 12.9805 25.9268 12.9822 27.3351 13.1715C28.7201 13.3577 29.555 13.7119 30.1721 14.329C30.7893 14.9461 31.1434 15.7811 31.3296 17.166C31.5189 18.5743 31.5207 20.4249 31.5207 23.0013C31.5207 25.5777 31.5189 27.4283 31.3296 28.8366C31.1434 30.2215 30.7893 31.0565 30.1721 31.6736C29.555 32.2907 28.7201 32.6449 27.3351 32.8311C25.9268 33.0204 24.0762 33.0221 21.4998 33.0221C18.9234 33.0221 17.0729 33.0204 15.6645 32.8311C14.2796 32.6449 13.4447 32.2907 12.8275 31.6736C12.2104 31.0565 11.8563 30.2215 11.6701 28.8366C11.4807 27.4283 11.479 25.5777 11.479 23.0013C11.479 20.4249 11.4807 18.5743 11.6701 17.166C11.8563 15.7811 12.2104 14.9461 12.8275 14.329Z"
					fill="#343330"
				/>
			</svg>
		</div>
	);
};
