import cls from './DecorationItem.module.css';

export const DecorationItem = () => {
	return (
		<div className={cls.container}>
			<svg
				width="108"
				height="86"
				viewBox="0 0 108 86"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M1 85L36.1291 29.0823C47.1077 11.6067 66.2972 1 86.9352 1H108" stroke="#BABABA" />
			</svg>
		</div>
	);
};
