import cls from './TechnologyIcon.module.css';

export const TechnologyIcon = () => {
	return (
		<div className={cls.container}>
			<svg
				width="43"
				height="46"
				viewBox="0 0 43 46"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect y="0.5" width="43" height="45" rx="8" fill="#FFDA85" />
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M13.104 19.3992C11.2497 19.0228 9.854 17.3834 9.854 15.418C9.854 13.1743 11.6728 11.3555 13.9165 11.3555C15.8819 11.3555 17.5213 12.7511 17.8977 14.6055H25.1019C25.4783 12.7511 27.1178 11.3555 29.0832 11.3555C31.3268 11.3555 33.1457 13.1743 33.1457 15.418C33.1457 17.3834 31.75 19.0228 29.8957 19.3992V26.6034C31.75 26.9798 33.1457 28.6192 33.1457 30.5846C33.1457 32.8283 31.3268 34.6471 29.0832 34.6471C27.1178 34.6471 25.4783 33.2515 25.1019 31.3971H17.8977C17.5213 33.2515 15.8819 34.6471 13.9165 34.6471C11.6728 34.6471 9.854 32.8283 9.854 30.5846C9.854 28.6192 11.2497 26.9798 13.104 26.6034L13.104 19.3992ZM11.479 15.418C11.479 14.0718 12.5703 12.9805 13.9165 12.9805C15.2627 12.9805 16.354 14.0718 16.354 15.418C16.354 16.7642 15.2627 17.8555 13.9165 17.8555C12.5703 17.8555 11.479 16.7642 11.479 15.418ZM14.729 26.6034L14.729 19.3992C16.3208 19.0761 17.5746 17.8223 17.8977 16.2305H25.1019C25.4251 17.8223 26.6789 19.0761 28.2707 19.3992V26.6034C26.6789 26.9265 25.4251 28.1803 25.1019 29.7721H17.8977C17.5746 28.1803 16.3208 26.9265 14.729 26.6034ZM13.9165 28.1471C12.5703 28.1471 11.479 29.2384 11.479 30.5846C11.479 31.9308 12.5703 33.0221 13.9165 33.0221C15.2627 33.0221 16.354 31.9308 16.354 30.5846C16.354 29.2384 15.2627 28.1471 13.9165 28.1471ZM31.5207 15.418C31.5207 16.7642 30.4294 17.8555 29.0832 17.8555C27.737 17.8555 26.6457 16.7642 26.6457 15.418C26.6457 14.0718 27.737 12.9805 29.0832 12.9805C30.4294 12.9805 31.5207 14.0718 31.5207 15.418ZM26.6457 30.5846C26.6457 29.2384 27.737 28.1471 29.0832 28.1471C30.4294 28.1471 31.5207 29.2384 31.5207 30.5846C31.5207 31.9308 30.4294 33.0221 29.0832 33.0221C27.737 33.0221 26.6457 31.9308 26.6457 30.5846Z"
					fill="#343330"
				/>
			</svg>
		</div>
	);
};
