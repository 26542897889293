import cls from './LevelIcon.module.css';

export const LevelIcon = () => {
	return (
		<div className={cls.container}>
			<svg
				width="43"
				height="45"
				viewBox="0 0 43 45"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect width="43" height="45" rx="8" fill="#A66DFF" />
				<path
					d="M21.4382 10.8535H23.1253C23.5741 10.8535 23.9378 11.2173 23.9378 11.666C23.9378 12.1147 23.5741 12.4785 23.1253 12.4785H21.5003C18.9239 12.4785 17.0733 12.4802 15.665 12.6696C14.2801 12.8558 13.4451 13.2099 12.828 13.827C12.2109 14.4442 11.8568 15.2791 11.6706 16.6641C11.4812 18.0724 11.4795 19.9229 11.4795 22.4993C11.4795 25.0758 11.4812 26.9263 11.6706 28.3346C11.8568 29.7196 12.2109 30.5545 12.828 31.1717C13.4451 31.7888 14.2801 32.1429 15.665 32.3291C17.0733 32.5185 18.9239 32.5202 21.5003 32.5202C24.0767 32.5202 25.9273 32.5185 27.3356 32.3291C28.7206 32.1429 29.5555 31.7888 30.1726 31.1717C30.7898 30.5545 31.1439 29.7196 31.3301 28.3346C31.5194 26.9263 31.5212 25.0758 31.5212 22.4993V20.8743C31.5212 20.4256 31.8849 20.0618 32.3337 20.0618C32.7824 20.0618 33.1462 20.4256 33.1462 20.8743V22.5615C33.1462 25.0623 33.1462 27.0221 32.9406 28.5512C32.7302 30.1163 32.2911 31.3513 31.3217 32.3207C30.3523 33.2901 29.1173 33.7292 27.5521 33.9396C26.023 34.1452 24.0632 34.1452 21.5625 34.1452H21.4382C18.9374 34.1452 16.9776 34.1452 15.4485 33.9396C13.8834 33.7292 12.6484 33.2901 11.679 32.3207C10.7096 31.3513 10.2705 30.1163 10.0601 28.5512C9.85447 27.0221 9.85448 25.0623 9.85449 22.5615V22.4372C9.85448 19.9364 9.85447 17.9766 10.0601 16.4475C10.2705 14.8824 10.7096 13.6474 11.679 12.678C12.6484 11.7086 13.8834 11.2695 15.4485 11.0591C16.9776 10.8535 18.9374 10.8535 21.4382 10.8535Z"
					fill="white"
				/>
				<path
					d="M27.4371 19.7085C27.7819 19.9958 27.8284 20.5081 27.5412 20.8528L25.5599 23.2303C25.2041 23.6574 24.8833 24.0425 24.5837 24.313C24.2576 24.6074 23.847 24.8696 23.3059 24.8696C22.7648 24.8696 22.3541 24.6074 22.0281 24.313C21.7285 24.0425 21.4077 23.6574 21.0518 23.2303L20.7351 22.8502C20.3334 22.3682 20.0867 22.0752 19.8835 21.8917C19.7892 21.8066 19.7333 21.7727 19.7065 21.7597C19.7035 21.7583 19.7009 21.7571 19.6988 21.7562L19.6948 21.7546C19.6923 21.7555 19.6884 21.7571 19.683 21.7597C19.6562 21.7727 19.6003 21.8066 19.5061 21.8917C19.3028 22.0752 19.0562 22.3682 18.6545 22.8502L16.7078 25.1862C16.4206 25.5309 15.9082 25.5775 15.5635 25.2902C15.2188 25.0029 15.1722 24.4906 15.4595 24.1459L17.4407 21.7684C17.7966 21.3413 18.1173 20.9562 18.417 20.6857C18.743 20.3913 19.1537 20.1291 19.6948 20.1291C20.2359 20.1291 20.6465 20.3913 20.9726 20.6857C21.2722 20.9562 21.593 21.3413 21.9488 21.7684L22.2656 22.1485C22.6673 22.6305 22.914 22.9235 23.1172 23.107C23.2114 23.1921 23.2673 23.226 23.2941 23.239C23.2996 23.2416 23.3034 23.2432 23.3059 23.2441C23.3059 23.2441 23.3089 23.243 23.3109 23.242C23.3109 23.242 23.3151 23.2402 23.3177 23.239C23.3444 23.226 23.4004 23.1921 23.4946 23.107C23.6978 22.9235 23.9445 22.6305 24.3462 22.1485L26.2928 19.8125C26.5801 19.4678 27.0924 19.4212 27.4371 19.7085Z"
					fill="white"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M29.0837 10.8535C26.84 10.8535 25.0212 12.6724 25.0212 14.916C25.0212 17.1597 26.84 18.9785 29.0837 18.9785C31.3273 18.9785 33.1462 17.1597 33.1462 14.916C33.1462 12.6724 31.3273 10.8535 29.0837 10.8535ZM26.6462 14.916C26.6462 13.5698 27.7375 12.4785 29.0837 12.4785C30.4299 12.4785 31.5212 13.5698 31.5212 14.916C31.5212 16.2622 30.4299 17.3535 29.0837 17.3535C27.7375 17.3535 26.6462 16.2622 26.6462 14.916Z"
					fill="white"
				/>
			</svg>
		</div>
	);
};
